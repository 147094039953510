import React from 'react'
import PropTypes from 'prop-types'

const PartnerCard = ({ data: { title, url, file } }) => {
  return (
    <div className="flex flex-col">
      {url ? (
        <a href={url} target="__blank">
          <img alt={title} src={file} className="w-full" />
          {/* <span className="bg-teal-dark text-white tracking-widest block p-2 text-sm">
            {title}
          </span> */}
        </a>
      ) : (
        <div>
          <img alt={title} src={file} className="w-full" />
          {/* <span className="bg-teal-dark text-white tracking-widest block p-2 text-sm">
            {title}
          </span> */}
        </div>
      )}
    </div>
  )
}

PartnerCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    file: PropTypes.string,
  }),
}

export default PartnerCard
